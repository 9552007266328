<template>
    <div class="layout-header">
        <ul>
            <li class="user-avatar"><img :src="logoUrl"></li>
            <li class="user-line"></li>
            <li class="user-header-logo">{{ state.softwareTitle || 'AR智能服务平台' }}</li>
            <li class="user-header-tab">
                <a href="javascript:" class="li" :class="headerMenuId === menu.code || !headerMenuId ?'active':''" :key="key" v-for="(menu,key) in menuList" @click="onHeaderMenu(menu.code)">{{ menu.value }}</a>
            </li>
            <li class="user-box">
                <div class="link" v-if="state.isAdmin">
                    <a :href="linkUrl === 'production'?'https://admin.lingyun.ustep.cn/':'https://test.admin.lingyun.ustep.cn/'">进入后台管理  <Icon type="md-arrow-forward" /></a>
                </div>
<!--                <div class="link" v-if="state.isAdmin">-->
<!--                    <a href="https://10.21.102.105:80/login">进入后台管理  <Icon type="md-arrow-forward" /></a>-->
<!--                </div>-->
                <Dropdown trigger="click" placement="bottom-end">
                    <div class="user-info" @click="onModalCode">
                        <Avatar :src="state.profilePictureUrl || defaultAvatar" class="avatar" icon="ios-person"/>
                        <font>{{ state.name }}</font>
                    </div>
                    <div class="user-item" slot="list">
                        <div class="item-top">
                            <Avatar shape="square" :src="state.profilePictureUrl || defaultAvatar" class="avatar" icon="ios-person"/>
                            <dl>
                                <dt>{{ state.name }}</dt>
<!--                                <dd>部门&#12288;职务</dd>-->
                            </dl>
                        </div>
                        <div class="item-code">
                            <h2 class="title">眼镜端登录二维码</h2>
                            <h3 class="text">使用眼镜扫码&#12288;登录远程协作</h3>
                            <div class="code">
                                <vue-qr :logoSrc="logoSrc" :text="QRCode" class="layout-code"></vue-qr>
                            </div>
                            <Button type="error" long @click="onSignOut">退出登录</Button>
                        </div>
                    </div>
                </Dropdown>
            </li>
        </ul>
    </div>
</template>

<script>
import Header from './index'
export default Header
</script>

