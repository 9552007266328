import Vue from 'vue'
import { Message,Avatar } from 'view-design';
Vue.component('Avatar', Avatar);
import { getDeviceClassAllService } from '@/service/device-class-service'
import { mapMutations,mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
/**
 * menu list 菜单列表
 * @type {{ menuList }}
 */
const MenuKnowledge = {
    name:'MenuKnowledge',
    computed: {
        ...mapState({
            selectedMenuId: state => state.system.selectedMenuId, //选中的菜单Id
            menuVisible: state => state.system.menuVisible, //菜单显示状态
        }),
    },
    data() {
        return {
            deviceListArray:[], //设备分类数据
        }
    },
    created() {
        this.onDeviceClassAll().then()
    },
    methods:{
        ...mapMutations('system',['SET_CLASSIFY_MENU_ARRAY','SET_SELECTED_MENU_ID']),
        /**
         * 跳转携带id
         * @param id
         */
        onLinks(deviceId,childrenId,childId){
            this.SET_SELECTED_MENU_ID([-1,childrenId,childId])
            if(this.$route.path!=='/device/list'){
                this.$router.push('/device/list')
            }else{
                $event.$emit(EVENT_NAME.DEVICE_MENU_LIST)
            }
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.value = list.id;
                newData.label = list.name;
                newData.visible = list.visible;
                newData.children = list.childNode;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 获取所有设备分类
         */
        async onDeviceClassAll() {
            try {
                const { code,data } = await getDeviceClassAllService()
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].name = '目录'
                        data[i].visible = true
                        data[i].id = -1
                        if(data[i].childNode.length!==0){
                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.deviceListArray = this.onReplaceAttribute(data)
                    this.SET_CLASSIFY_MENU_ARRAY(this.deviceListArray)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
    },
    destroyed () {

    }
}
export default MenuKnowledge
