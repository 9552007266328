import Vue from "vue";
import AgoraRTC from 'agora-rtc-sdk-ng';
// import AgoraRTC from '@/api/AgoraRTC_N-4.22.2';
import {Avatar, Message, Spin, Icon, Input, Tabs, TabPane, Table, Modal} from 'view-design';
Vue.component('Avatar', Avatar);
Vue.component('Spin', Spin);
Vue.component('Icon', Icon);
Vue.component('Input', Input);
Vue.component('Tabs', Tabs);
Vue.component('TabPane', TabPane);
Vue.component('Table', Table);
Vue.component('Modal', Modal);
import Cookies from 'js-cookie'
import { getWssUrl,colorPicker,lineStroke,getRoomAliveInfoService } from '@/service/user-service'
import $event,{ EVENT_NAME } from '@/utils/event-emitter';
import { isMobile,dataURLtoFile,renderSize,sessionTime,clearSessionTime } from '@/utils/tools'
import {mapMutations, mapState} from "vuex";
import MenuList from '@/components/menuList/index.vue'
import MenuKnowledge from '@/components/menuKnowledge/index.vue'
import MenuWorkflow from '@/components/menuWorkflow/index.vue'
import Header from '@/components/Header/index.vue'
import defaultAvatar from '@/assets/Image/default-avatar.png'
import Called from '@/components/Called/called.vue';
import Sign from '@/components/Sign'
import Chat from '@/components/Chat'
import Screenshot from '@/components/Screenshot/screenshot.vue'
import {
    RECEIVE_INSTRUCTIONS,
    SEND_COMMAND,
} from '@/service/user-service'
import {
    getDeviceInfoService,
    getDeviceListService,
    deviceDataVideoColumns,
    getFailureListService
} from "@/service/device-info-service";
import { systemUploadType } from '@/service/system-service'
import { onSysncUploadFiles } from '@/utils/request-function'
import { systemPublishEnum,systemPublishLogo } from '@/service/system-service'
const Index = {
    name:'Index',
    components:{ MenuList,Header,Called,Screenshot,MenuKnowledge,MenuWorkflow },
    computed: {
        ...mapState({
            state: state => state.userCenter.userInfo, //获取状态管理用户中心
            stataCalled: state => state.userCenter.visibleCalled, //获取状态管理用户中心
        }),
        reverseCamera() {
            return this.cameraMaxZoom.reverse()
        },
        /**
         * 验证共享状态下显示样式
         * @returns {function(*): string}
         */
        onShareStyle(){
            return (obj) =>{
                let className = ''
                if(obj.pushStream && obj.share){
                    className = 'share-stream'
                }
                if(!obj.pushStream && obj.share){
                    className = 'share'
                }
                if(!obj.share){
                    className = 'black'
                }
                return className
            }
        },
    },
    data() {
        return {
            headerMenuId:1,
            defaultAvatar:defaultAvatar,
            screenLoading:false,
            menuVisible:true,
            fullscreen: false,
            systemPublishMenu:systemPublishEnum, //配置演示状态枚举
            systemPublishLogo:systemPublishLogo, //配置演示状态
            userStatusInfo:{}, //用户状态变动数据
            memberListVisible:false, //邀请成员列表可显示
            cameraMaxZoom:0, //相机放大等级
            selectCameraZoom:0, //选中的放大等级
            screenshotObj:{},//截图存储，方便重新绘制
            windowWidth:window.innerWidth,
            agoraVideoLoading:false, //视频等待效果
            videoClientStatus:false, //视频开启状态  //
            videoScreenSwitch:false, //视频截图切换
            shareVideoVisible:false, //共享缩略图切换
            status:1, //接收／发起状态
            visibleCalled: false, //呼叫以及来电弹框
            signColorVisible:false, //标记选择框
            cameraZoomVisible:false, //镜头缩放弹框可显示
            graffitiVisible:false, //涂鸦选择框
            operationVisible:false, //底部操作区域可显示  //
            operationTopVisible:false, //头部操作区域可显示
            volumeStatus:true, //音频轨道状态 true播放 false关闭
            voiceStatus:true, //声音状态 true 播放 false 关闭
            screenshotVisible:false, //截图区域可显示
            screenshotUid:'', //截图时的uid
            screenImage:'', //截图视频切换时抓取的图片
            screenshotOut:false, //退出截图
            lineVisible:false, //线条选择框
            textVisible:false, //输入框可显示
            textChatVisible:false, //输入框可显示
            shareStatus:false, //共享状态
            newsInput:'', //输入框文字
            send:'',
            active:0,
            screenshotActive:0,
            linkStatus:false, //链接状态
            linkLoading:false, //连接状态等待
            calledInfo:{},
            callTime:0,
            intervalId:null,
            sessionTime:'00 : 00 : 00',
            signColor:'#33E633', //标记拾色器
            graffitiColor:'#33E633', //涂鸦拾色器
            lineStroke: 2, //线条宽度
            colorPickerObj:colorPicker, //颜色数据
            lineStrokeObj:lineStroke,
            pushStreamAgoraId:'',//推流用户在声网的id
            shareStreamVisible:false, //眼镜端共享可显示
            playerContainerId:'', //视频区域id
            agoraId:'', //声网id
            memberArray:[], //成员列表
            requestStatus:false,
            requestFun:{
                sendTimeOut:null, //发送心跳定时器
                timeoutService:null, //心跳检测
                heartbeatCounter:10, //心跳计数器
                timeOut: 1 * 1000, //1秒一次心跳
            },
            webSocketFun:{
                sendTimeOut:null, //发送心跳定时器
                timeoutService:null, //心跳检测
                heartbeatCounter:0, //心跳计数器
                timeOut: 15 * 1000, //15秒一次心跳
            },
            webRTC:{  //RTC配置参数
                client: null,  //放置本地客户端
                localAudioTrack: null, //放置本地音频频轨道对象
                localVideoTrack: null, //放置本地视频频轨道对象
                localScreenTrack:null, //屏幕共享本地视频轨道对象
                audioTrack:null, //远端音频轨道对象
                appId: "82bad3ec59654b0696e90e770eae75a5", //appId
                channel: "", //房间号
                token : '', //token
                deviceId:'',
            },
            deviceVisible:false,
            listVisible:true,
            renderSize:renderSize,
            deviceListArray:{}, //设备数据
            deviceInfoObj:{}, //设备信息数据
            deviceDataVideoColumns:deviceDataVideoColumns,
            deviceListParams:{ //提交的数据集
                search:'', //搜索条件
                currentPage: 1,  //当前页
                displayNumber:4 // 显示条数
            },
            faultsList:[  //故障分析数据集
                {
                    name:'',
                    describe:'',
                    reasons:[
                        {
                            reason:'',
                            solution:'',
                        }
                    ]
                }
            ],
            remoteVideoTrack:{},
            playerContainer:{},
            memberRemoteVideoTrack:{},
            memberPlayerContainer:{},
        }
    },
    created() {
        /**
         * 订阅协作对方
         */
        $event.$on(EVENT_NAME.COOPERATION_USER, (obj) => {
            if(!this.videoClientStatus){
                if(obj.onlineTerminal == 'PC'){
                    Message.warning('对方是专家端登录，不可协作对方！')
                }else{
                    this.calledInfo = obj
                    this.onCalledCooperation()
                }
            }
            if(this.videoClientStatus){
                if(obj.onlineTerminal == 'AR'){
                    Message.warning('对方是眼镜端口登录，不可请求协作！')
                }else{
                    this.calledInfo = obj
                    this.onCalledCollaboration()
                }
            }
        });
        /**
         * 订阅会话时间
         */
        $event.$on(EVENT_NAME.SESSION_TIME, (time) => {
            this.sessionTime = time
        })
        /**
         * 修改顶部菜单
         */
        $event.$on(EVENT_NAME.HEADER_MENU_TAB, (id) => {
            this.headerMenuId = id
        })
    },
    mounted() {
        this.onSocketInit() //websocket 实例化 mounted 调用
        window.addEventListener('keydown',this.onKeyDown)
        if(window.innerWidth<750){
            this.menuVisible = false
        }else{
            this.menuVisible = true
        }
        window.addEventListener('resize',()=>{ this.onWindowMenu() },false)
    },
    methods: {
        ...mapMutations('userCenter',['SET_VISIBLE_CALLED']),
        /**
         * 获取键盘Enter值 调用登录
         * @param e
         */
        onKeyDown(e) {
            if(e.keyCode === 13){
                this.onSignText()
            }
        },
        /**
         * 调整menu菜单
         */
        onWindowMenu(){
            if(window.innerWidth<=750){
                this.menuVisible = false
            }else{
                this.menuVisible = true
            }
            this.windowWidth = window.innerWidth
        },
        /**
         * 调整esc
         */
        onWindowEsc(){
            this.onWindow()
            if(window.innerWidth<=750){
                this.menuVisible = false
            }else{
                this.menuVisible = true
            }
            this.windowWidth = window.innerWidth
            // 全屏下监控是否按键了ESC
            if (!this.checkFull()) {
                // 全屏下按键esc后要执行的动作
                this.fullscreen = false;
            }
        },
        /**
         * 切换全屏
         */
        onScreen(){
            let element = document.documentElement;
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen();
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen();
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen();
                }
            }
            this.fullscreen = !this.fullscreen;
        },
        /**
         * 是否全屏并按键ESC键的方法
         */
        checkFull() {
            //判断浏览器是否处于全屏状态 &#xff08;需要考虑兼容问题&#xff09;
            //火狐浏览器
            let isFull = document.mozFullScreen|| document.fullScreen || document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled
            if(isFull === undefined) {
                isFull = false
            }
            return isFull;
        },
        /**
         * 可关闭菜单
         */
        onMenuBack(){
            const videoHandle = document.getElementById('videoHandle')
            if(this.menuVisible){
                this.menuVisible = false
                if(window.innerWidth*0.5625>=document.getElementById('agoraRTC').offsetHeight && window.innerWidth>750){
                    videoHandle.style.width = document.getElementById('agoraRTC').offsetHeight*1.777777777777778+'px'
                    videoHandle.style.height = document.getElementById('agoraRTC').offsetHeight+'px'
                }else{
                    videoHandle.style.width = '100%'
                    videoHandle.style.height = window.innerWidth * 0.5625+'px'
                }
            }else{
                this.menuVisible = true
                if((window.innerWidth-270)*0.5625<=document.getElementById('agoraRTC').offsetHeight && window.innerWidth>750){
                    videoHandle.style.width = '100%'
                    videoHandle.style.height = (videoHandle.offsetWidth-270) * 0.5625+'px'
                }
            }
            if(window.innerWidth>750 && this.screenshotVisible){
                $event.$emit(EVENT_NAME.CANVAS_RESIZE);
            }
        },
        /**
         * 窗口变化显示视频区域大小变化
         */
        onWindow(){
            const videoHandle = document.getElementById('videoHandle')
            videoHandle.style.width = document.getElementById('agoraRTC').offsetHeight*1.777777777777778+'px'
            videoHandle.style.height = document.getElementById('agoraRTC').offsetHeight+'px'
            if(document.getElementById('agoraRTC').offsetWidth<=videoHandle.offsetWidth){
                videoHandle.style.width = '100%'
                videoHandle.style.height = document.getElementById('agoraRTC').offsetWidth * 0.5625+'px'
            }
            if(this.screenshotVisible){
                $event.$emit(EVENT_NAME.CANVAS_RESIZE);
            }
        },
        /**
         * 切换回视频
         */
        onVideoScreenSwitch(){
            this.videoScreenSwitch = true
            this.screenshotVisible = false
            this.remoteVideoTrack.play(this.playerContainer);
            this.onVideoCanvas()
            for(let i in this.memberArray){
                if(this.memberArray[i].uid === this.screenshotUid){
                    this.memberArray.unshift(this.memberArray.splice(i,1)[0])
                }
            }
        },
        /**
         * 提取视频图片
         */
        onVideoCanvas(){
            let canvas = document.getElementById('canvas')
            let base64 = canvas.toDataURL('images/png')
            this.screenImage = base64
        },
        /**
         * 切换回截图
         */
        onScreenVideoSwitch(){
            this.videoScreenSwitch = false
            this.screenshotVisible = true
            this.screenImage = ''
            for(let i in this.memberArray){
                if(this.memberArray[i].pushStream){
                    this.memberArray.unshift(this.memberArray.splice(i,1)[0])
                }
            }
            this.$nextTick(()=>{
                document.getElementById('memberVideo').append(this.memberPlayerContainer)
                this.memberRemoteVideoTrack.play(this.memberPlayerContainer);
            })
        },
        async onWebRTCStartUp(){
            this.agoraVideoLoading = true;
            /**
             *  创建本地客户端
             * @type {Object}
             */
            this.webRTC.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
            await AgoraRTC.enableLogUpload()

            /**
             * 监听远端用户 ps:创建客户端对象之后立即监听事件，以避免错过任何事件
             */

            let joinChannel
            console.log( this.webRTC.channel)
            if( process.env.NODE_ENV === 'privatization'){
                // var t = JSON.parse("{\"log\": {}, \"report\": {} , \"accessPoints\": {\"serverList\": [\"private.rtcdeveloper.second.com\"], \"domain\": \"rtcdeveloper.second.com\"}}");
                    AgoraRTC.setParameter("JOIN_WITH_FALLBACK_SIGNAL_PROXY", false);
                    AgoraRTC.setParameter("JOIN_WITH_FALLBACK_MEDIA_PROXY", false);
                    AgoraRTC.setParameter('CONNECT_GATEWAY_WITHOUT_DOMAIN',true)
                    AgoraRTC.setParameter("WEBCS_DOMAIN",["10.21.102.107"]);
                    AgoraRTC.setParameter("WEBCS_DOMAIN_BACKUP_LIST",["10.21.102.107"]);
                    AgoraRTC.setParameter("GATEWAY_DOMAINS",["rtcdeveloper.com"]);
                this.onUserPublished()
                joinChannel =  await this.webRTC.client.join(this.webRTC.appId, this.webRTC.channel, null,this.agoraId);
            }else{
                /**
                 *  加入目标频道
                 * @type {*}
                 */
                this.onUserPublished()
                 joinChannel =  await this.webRTC.client.join(this.webRTC.appId, this.webRTC.channel,this.webRTC.token,this.agoraId,null);
            }
            /**
             * 通过麦克风采集的音频创建本地音频轨道对象。
             * @type {*}
             */
            const localAudioTrack =  this.webRTC.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

            let publish = await this.webRTC.client.publish([this.webRTC.localAudioTrack]).then();
            /**
             * 由于加入频道和创建本地音视频轨道没有依赖关系，所以利用 Promise.all 同时执行这些异步操作。
             */
            Promise.all([joinChannel,localAudioTrack,publish]).then();

            this.webRTC.client.enableAudioVolumeIndicator();
            this.webRTC.client.on("volume-indicator", volumes => {
                const max = Math.max.apply(Math,volumes.map(item => {return item.level}))
                for(let i in this.memberArray){
                    for(let j in volumes){
                        if(this.memberArray[i].agoraId === volumes[j].uid){
                            this.memberArray[i].number = volumes[j].level
                        }
                    }
                }
                for(let i in this.memberArray){
                    if(this.memberArray[i].number === max){
                        this.memberArray[i].visible = true
                    }else{
                        this.memberArray[i].visible = false
                    }
                }
                this.$forceUpdate()
            })
        },
        /**
         * 订阅远端用户
         */
        onUserPublished() {
            this.webRTC.client.on("user-published", async (user, mediaType) => {
                // if(user.uid === this.pushStreamAgoraId){
                    // 开始订阅远端用户。
                    await this.webRTC.client.subscribe(user, mediaType);
                    // 表示本次订阅的是视频。
                    if (mediaType === "video") {
                        // 订阅完成后，从 `user` 中获取远端视频轨道对象。
                        this.remoteVideoTrack = user.videoTrack;
                        this.memberRemoteVideoTrack = user.videoTrack;
                        // 动态插入一个 DIV 节点作为播放远端视频轨道的容器。
                        this.playerContainer = document.createElement("div");
                        this.memberPlayerContainer = document.createElement("div");
                        // 给这个 DIV 节点指定一个 ID，这里指定的是远端用户的 UID。
                        this.playerContainer.id = this.pushStreamAgoraId.toString();
                        this.memberPlayerContainer.id = this.pushStreamAgoraId.toString();
                        this.playerContainerId = this.pushStreamAgoraId.toString()
                        this.playerContainer.className = 'agora_video_player-published'
                        // playerContainer.style.width = document.getElementById('agoraRTC').offsetHeight*1.777777777777778+'px'
                        // playerContainer.style.height = document.getElementById('agoraRTC').offsetHeight+'px'
                        const videoHandle = document.getElementById('videoHandle')
                        videoHandle.style.width = document.getElementById('agoraRTC').offsetHeight*1.777777777777778+'px'
                        if(document.getElementById('agoraRTC').offsetWidth<=videoHandle.offsetWidth){
                            videoHandle.style.width = '100%'
                            videoHandle.style.height = document.getElementById('agoraRTC').offsetWidth * 0.5625+'px'
                        }
                        document.getElementById('videoBox').append(this.playerContainer)
                        document.getElementById('memberVideo').append(this.memberPlayerContainer)

                        // 订阅完成，播放远端音视频。
                        // 传入 DIV 节点，让 SDK 在这个节点下创建相应的播放器播放远端视频。
                        this.remoteVideoTrack.play(this.playerContainer);
                        // 也可以只传入该 DIV 节点的 ID。
                        // remoteVideoTrack.play(playerContainer.id);
                        window.addEventListener('resize',()=>{ this.onWindowEsc() },false)
                    }
                    // 表示本次订阅的是音频。
                    if (mediaType === "audio") {
                        // 订阅完成后，从 `user` 中获取远端音频轨道对象。
                        this.webRTC.audioTrack = user.audioTrack;
                        // 播放音频因为不会有画面，不需要提供 DOM 元素的信息。
                        if(this.voiceStatus){
                            this.voiceStatus = true
                            this.webRTC.audioTrack.play()
                        }else{
                            this.voiceStatus = false
                            this.webRTC.audioTrack.stop()
                        }
                    }
                    this.agoraVideoLoading = false;
                    this.operationTopVisible = true
                    this.textVisible = true
                    sessionTime()
                // }else{
                //     this.onSharePublished(user.uid)
                // }
            });
        },
        /**
         * 订阅远端共享用户
         */
        onSharePublished(id) {
            this.webRTC.client.on("user-published", async (user, mediaType) => {
                if(user.uid === id){
                    // 开始订阅远端用户。
                    await this.webRTC.client.subscribe(user, mediaType);
                    // 表示本次订阅的是视频。
                    if (mediaType === "video") {
                        // 订阅完成后，从 `user` 中获取远端视频轨道对象。
                        const remoteVideoTrack = user.videoTrack;
                        // 动态插入一个 DIV 节点作为播放远端视频轨道的容器。
                        const playerContainer = document.createElement("div");
                        // 给这个 DIV 节点指定一个 ID，这里指定的是远端用户的 UID。
                        playerContainer.id = id;
                        playerContainer.className = 'agora_video_player-published'
                        const videoHandle = document.getElementById('share')
                        videoHandle.append(playerContainer)
                        // 订阅完成，播放远端音视频。
                        // 传入 DIV 节点，让 SDK 在这个节点下创建相应的播放器播放远端视频。
                        remoteVideoTrack.play(playerContainer);
                        // 也可以只传入该 DIV 节点的 ID。
                        // remoteVideoTrack.play(playerContainer.id);
                    }
                }
            });
        },
        /**
         * 离开webRTC频道
         */
        async onLeaveCall() {
            this.sessionTime = '00 : 00 : 00'
            clearSessionTime()
            this.agoraVideoLoading = false;
            this.videoClientStatus = false
            this.operationTopVisible = false
            this.volumeStatus = true
            this.voiceStatus = true
            this.operationVisible = false
            this.screenshotVisible = false
            this.screenshotOut = false
            this.shareStatus = false
            this.cameraZoomVisible = false
            this.webRTC.localScreenTrack = null
            this.videoScreenSwitch = false
            this.screenshotUid = ''
            this.SET_VISIBLE_CALLED(false)
            // 销毁本地音视频轨道。
            this.webRTC.localAudioTrack.close();
            // 遍历远端用户。
            this.webRTC.client.remoteUsers.forEach(user => {
                // 销毁动态创建的 DIV 节点。
                const playerContainer = document.getElementById(user.uid);
                playerContainer && playerContainer.remove();
            });
            // 离开频道。
            await this.webRTC.client.leave();
        },
        /**
         * 开始计时
         */
        startTime(){
            this.intervalId = setInterval(()=>{
                this.callTime++
                if(this.callTime===16){
                    this.visibleCalled = false
                    this.SET_VISIBLE_CALLED(false)
                    this.onCancel()
                    Message.info('对方无回应！')
                    this.clearInterval()
                    this.$refs.ringtones.pause()
                    this.$refs.ringtones.load()
                    this.$refs.hangUp.play()
                }
            },1000)
        },
        /**
         * 清除计时
         */
        clearInterval() {
            this.callTime = 0
            clearInterval(this.intervalId)
        },
        /**
         * 发起协作对方
         */
        onCalledCooperation(){
            this.$refs.ringtones.play()
            this.status = 1
            this.visibleCalled = true
            this.SET_VISIBLE_CALLED(true)
            this.startTime();
            let params = {
                action:SEND_COMMAND.COOPERATION_USER,
                uid:this.calledInfo.uid
            }
            this.onWebSocketSend(params)
        },
        /**
         * 请求协作
         * @param userInfo
         */
        onCalledCollaboration(){
            this.$refs.ringtones.play()
            this.status = 1
            this.visibleCalled = true
            this.SET_VISIBLE_CALLED(true)
            this.startTime();
            let params = {
                action:SEND_COMMAND.REQUEST_COOPERATION,
                uid:this.calledInfo.uid
            }
            this.onWebSocketSend(params)
        },
        /**
         * 取消
         */
        onCancel(){
            this.visibleCalled = false
            this.SET_VISIBLE_CALLED(false)
            this.clearInterval()
            let params = {
                action:SEND_COMMAND.CALL_HANG_UP,
            }
            this.onWebSocketSend(params)
            this.$refs.ringtones.pause()
            this.$refs.ringtones.load()
            this.$refs.hangUp.play()
        },
        /**
         * 拒绝接听
         */
        onCalledHangUp() {
            let params = {
                action:SEND_COMMAND.DECLINED,
                toUid:this.calledInfo.toUid
            }
            this.onWebSocketSend(params)
            this.$refs.ringtones.pause()
            this.$refs.ringtones.load()
            this.$refs.hangUp.play()
        },
        /**
         * 接受邀请
         * @returns { socket<json> }
         */
        onCalledAnswer() {
            this.headerMenuId = 1
            $event.$emit(EVENT_NAME.HEADER_MENU_TAB,1)
            this.$router.push('/')
            this.$refs.ringtones.pause()
            this.$refs.ringtones.load()
            let params = {
                action:SEND_COMMAND.ACCEPT,
                toUid:this.calledInfo.toUid
            }
            this.onWebSocketSend(params)
        },
        /**
         * 操作挂断 退出会话
         */
        onHandUp(){
            this.onLeaveCall().then()
            let params = {
                action:SEND_COMMAND.EXIT_SESSION,
                sessionId:this.webRTC.channel
            }
            this.onWebSocketSend(params)
        },
        /**
         * 退出截图
         */
        onSignOut(){
            this.operationVisible = true
            this.screenshotVisible = false
            this.screenshotOut = false
            this.videoScreenSwitch = false
            this.screenshotUid = ''
            this.active = 0;
            this.remoteVideoTrack.play(this.playerContainer);
            $event.$emit(EVENT_NAME.EMPTY_LINE_DATA); //清除数组

            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "CLOSE_DRAWING",
                content:{
                    'visible':this.screenshotVisible,
                }
            }
            this.onWebSocketSend(params)
            this.screenLoading = false
            for(let i in this.memberArray){
                if(this.memberArray[i].pushStream){
                    this.memberArray.unshift(this.memberArray.splice(i,1)[0])
                }
            }
            this.$nextTick(()=>{
                document.getElementById('memberVideo').append(this.memberPlayerContainer)
            })
        },
        /**
         * 发送文字
         */
        onSignText() {
            if(!this.videoClientStatus){
                return
            }
            if(this.newsInput == ''){
                Message.warning('请输入您要发送的消息！')
                return;
            }
            Chat.info({
                name:'我',
                type:'text',
                color:'#F8B551',
                content: this.newsInput,
            })
            let params = {
                action:SEND_COMMAND.SEND_TEXT,
                content:this.newsInput
            }
            this.newsInput = ''
            this.onWebSocketSend(params)
        },
        /**
         * 收起弹框
         */
        onPutAway(){
            this.signColorVisible?this.signColorVisible = false:''
            this.cameraZoomVisible?this.cameraZoomVisible = false:''
            this.graffitiVisible?this.graffitiVisible = false:''
            this.lineVisible?this.lineVisible = false:''
            this.active = 0
            this.screenshotActive = 0
        },
        async onSignUpLoad(e) {
            let files = e.target.files[0]
            let reader = new FileReader()
            reader.readAsDataURL(files)
            reader.onload = function(e) {
                Chat.info({
                    name:'我',
                    type:'image',
                    content: e.target.result,
                })
            }
            onSysncUploadFiles([files],systemUploadType.conversation_session_image).then((data)=>{
                let params = {
                    action:SEND_COMMAND.SEND_IMAGE,
                    content:{
                        id:data[0].resourceName,
                        url:data[0].url,
                    }
                }
                this.onWebSocketSend(params)
            }).catch(()=>{
                Message.error('发送失败，请重新尝试！')
            })
            this.$refs.inputer.value = ''
        },
        /**
         * 判断状态
         * @param status
         */
        onShareStatus(status) {
            let className = ''
            if(status === 5){
                className = 'active'
            }
            if(this.shareStatus){
                className = 'disabled'
            }
            return className
        },
        /**
         * 发送选中放大等级
         * @param key
         */
        onSelectCameraZoom(key) {
            this.selectCameraZoom = key
            let params = {
                action:SEND_COMMAND.CAMERA_CONTROLLER_SET,
                zoom:key
            }
            this.onWebSocketSend(params)
        },
        /**
         * 功能按钮选中
         * @param n
         */
        onHandleSelect(n){
            this.active = n
            this.signColorVisible = false
            this.cameraZoomVisible = false
            if(n === 1){
                this.signColorVisible = true
            }
            if(n === 2){
                this.operationVisible = false
                this.screenshotOut = true
                this.screenLoading = true
                this.screenshotVisible = true
                this.screenshotUid = this.state.uuid
                $event.$emit(EVENT_NAME.SUBSCRIBER_SCREENSHOT,this.videoClientStatus); //发送截图指令
                let canvas = document.getElementById('definition')
                let base64 = canvas.toDataURL('images/png')
                let imgFile = dataURLtoFile(base64,"img.png")

                this.screenLoading = true
                onSysncUploadFiles([imgFile],systemUploadType.conversation_session_image).then((data)=>{
                    let position = {'visible':this.screenshotVisible,url:data[0].url,}
                    let params = {
                        action:SEND_COMMAND.SEND_ACTION,
                        type: "OPEN_DRAWING",
                        content:position
                    }
                    this.onWebSocketSend(params)
                    let status = {
                        videoClientStatus:this.screenshotVisible,
                        url:data[0].url
                    }
                    this.screenshotObj = status
                    $event.$emit(EVENT_NAME.SUBSCRIBER_DRAW,status)
                }).catch(()=>{
                    Message.error('截图失败，请稍后再试！')
                    this.operationVisible = true
                    this.screenshotVisible = false
                    this.screenshotOut = false
                    this.active = 0;
                    this.screenLoading = false
                })
                this.memberRemoteVideoTrack.play(this.memberPlayerContainer);
                // this.onSignFormData(imgFile.name.substring(imgFile.name.lastIndexOf('.') + 1)).then(()=>{
                //     this.screenshotFromData(imgFile).then()
                // },()=>{
                //     Message.error('截图失败，请稍后再试！')
                //     this.operationVisible = true
                //     this.screenshotVisible = false
                //     this.screenshotOut = false
                //     this.active = 0;
                //     this.screenLoading = false
                // })

                // let position = {'actionName':'screenshot','value':this.screenshotVisible}

                // let params = {
                //     action:SEND_COMMAND.SEND_ACTION,
                //     content:position
                // }
                // this.onWebSocketSend(params)
            }
            // if(n === 4){
            //     this.textVisible?this.textVisible = false:this.textVisible = true
            // }
            if(n === 3){
                Message.warning('此功能暂未开放')
            }
            if(n === 4){
                Message.warning('此功能暂未开放')
            }
            if(n === 5){
                // Message.warning('此功能暂未开放')
                if(this.shareStatus){
                    return
                }
                let params = {
                    action:SEND_COMMAND.APPLY_SCREEN_SHARING,
                }
                this.onWebSocketSend(params)
            }
            if(n === 6){
                this.deviceVisible = true
            }
            if(n === 7){
                this.cameraZoomVisible = true
            }
        },
        onScreenshot(){
            this.screenLoading = false
        },
        /**
         * 发送涂鸦坐标(绘画)
         * @param obj
         */
        onGraffiti(obj) {
            let position = {'uid':this.state.uuid,'color':obj.color,'thickness':obj.thickness,'pageX':obj.pageX,'pageY':obj.pageY,'pagePX':obj.pagePX,'pagePY':obj.pagePY}
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "DRAWING",
                content:position
            }
            this.onWebSocketSend(params)
        },
        /**
         * 记录鼠标按下动作
         */
        onMouseDown(obj) {
            let position = {'uid':this.state.uuid,'color':obj.color,'thickness':obj.thickness,'pageX':obj.pageX,'pageY':obj.pageY,'pagePX':obj.pagePX,'pagePY':obj.pagePY,'lineName':obj.lineName}
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "DRAWING_DOWN",
                content:position
            }
            this.onWebSocketSend(params)
        },
        /**
         * 记录鼠标抬起动作
         */
        onMouseUp(obj) {
            let position = {'uid':this.state.uuid,'color':obj.color,'thickness':obj.thickness,'pageX':obj.pageX,'pageY':obj.pageY,'pagePX':obj.pagePX,'pagePY':obj.pagePY,'lineName':obj.lineName}
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "DRAWING_UP",
                content:position
            }
            this.onWebSocketSend(params)
        },
        /**
         * 开启\关闭 音频轨道
         */
        onVolume() {
            if(this.volumeStatus){
                this.volumeStatus = false
                this.webRTC.localAudioTrack.setEnabled(false).then()
            }else{
                this.volumeStatus = true
                this.webRTC.localAudioTrack.setEnabled(true).then()
            }
            let params = {
                action:SEND_COMMAND.MICROPHONE_STATE_SET,
                enable:this.volumeStatus
            }
            this.onWebSocketSend(params)
            for(let i in this.memberArray){
                if(this.memberArray[i].uid === this.state.uuid){
                    this.memberArray[i].microphoneEnabled = this.volumeStatus
                }
            }
            this.$forceUpdate()
        },
        /**
         * 开启\关闭 声音
         */
        onVoice(){
            if(this.voiceStatus){
                this.voiceStatus = false
                this.webRTC.audioTrack.stop()
            }else{
                this.voiceStatus = true
                this.webRTC.audioTrack.play()
            }
        },
        /**
         * 截图功能按钮选中
         * @param n
         */
        onScreenshotSelect(n){
            this.screenshotActive = n
            this.graffitiVisible = false
            this.lineVisible = false
            if(n === 1){
                this.graffitiVisible = true
            }
            if(n === 2){
                this.lineVisible = true
            }
            if(n === 3){
                this.onUndoDraw()
            }
            if(n === 4){
                this.onRemoveGraffiti()
            }
            if(n === 5){
                this.onSendImage()
            }
        },
        /**
         * 发送截图文件
         */
        onSendImage() {
            let canvas = document.getElementById('canvas')
            let base64 = canvas.toDataURL('images/png')
            let imgFile = dataURLtoFile(base64,"img.png")
            Chat.info({
                name:'我',
                type:'image',
                content: base64,
            })
            onSysncUploadFiles([imgFile],systemUploadType.conversation_session_image).then((data)=>{
                let params = {
                    action:SEND_COMMAND.SEND_IMAGE,
                    content:{
                        id:data[0].resourceName,
                        url:data[0].url,
                    }
                }
                this.onWebSocketSend(params)
            }).catch(()=>{
                Message.error('发送失败，请重新尝试！')
            })
        },
        /**
         * 切换标记颜色
         */
        onSignColor(color) {
            this.signColor = color
            this.signColorVisible = false
        },
        /**
         * 切换涂鸦颜色
         */
        onGraffitiColor(color) {
            this.graffitiColor = color
            this.graffitiVisible = false
        },
        /**
         * 切换线条宽度
         * @param line
         */
        online(line){
            this.lineStroke = line
            this.lineVisible = false
        },
        /**
         * 发送标记 （点）
         * @param e
         */
        onSignDrop(e){
            if(this.screenshotVisible){ //截图过程中不可发送标记
                return;
            }
            let dom = document.getElementById('videoHandle')
            Sign.info({
                offsetLeft:e.offsetX,
                offsetTop:e.offsetY,
                color:this.signColor,
                signName:this.state.name
            })
            let position = {'pageX':e.offsetX/dom.offsetWidth,'pageY':e.offsetY/dom.offsetHeight,'signColor':this.signColor,'signName':this.state.name}
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type:'TAG',
                content:position
            }
            this.onWebSocketSend(params)
        },
        /**
         * 撤销绘制
         */
        onUndoDraw() {
            $event.$emit(EVENT_NAME.UNDO_DRAW,this.state.uuid);
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "DRAWING_RECALL",
                content:{
                    uid:this.state.uuid
                }
            }
            this.onWebSocketSend(params)
        },
        /**
         *  删除涂鸦
         */
        onRemoveGraffiti() {
            $event.$emit(EVENT_NAME.REMOVE_GRAFFITI,this.state.uuid);
            let params = {
                action:SEND_COMMAND.SEND_ACTION,
                type: "DRAWING_CLEAR",
                content:{
                    uid:this.state.uuid
                }
            }
            this.onWebSocketSend(params)
        },
        onRequestCancel() {
            this.requestStatus = false
            var params = {
                action:SEND_COMMAND.LOGIN_SWITCH_ACTION,
                whetherSwitch:false
            }
            this.onWebSocketSend(params)
            this.requestFun.heartbeatCounter = 10
            clearTimeout(this.requestFun.timeoutService);
        },
        onRequestOk() {
            this.requestStatus = false
            var params = {
                action:SEND_COMMAND.LOGIN_SWITCH_ACTION,
                whetherSwitch:true
            }
            this.requestFun.heartbeatCounter = 10
            this.onWebSocketSend(params)
            clearTimeout(this.requestFun.timeoutService);
        },
        startRequestHeartBeat() {
            this.requestFun.timeoutService = setTimeout(()=>{
                this.startRequestHeartBeat()
                this.requestFun.heartbeatCounter-=1
                if(this.requestFun.heartbeatCounter === 0){
                    this.onRequestOk()
                }
            },1000)
        },
        /**
         * 订阅多人加入会话音频方式
         * @returns {Promise<void>}
         */
        async onSubscribeAudio() {
            this.webRTC.client.on("user-published", async (user) => {
                await this.webRTC.client.subscribe(user,"audio");
                user.audioTrack.play();
            })
        },
        onSocketInit() {
            /**
             * 创建webSocket 链接
             * @type {WebSocket}
             */
            this.websocket = new WebSocket(getWssUrl.concat('?token='+Cookies.get('token')+'&terminal='+isMobile()+'&deviceInfo='+window.innerWidth+'X'+window.innerHeight));
            /**
             * 打开监听 调用 onWebSocketOpen
             * @type {Index.methods.onWebSocketOpen}
             */
            this.websocket.onopen = this.onWebSocketOpen;
            /**
             * 关闭监听 调用 onWebSocketClose
             * @type {Index.methods.onWebSocketClose}
             */
            this.websocket.onclose = this.onWebSocketClose;
            /**
             * 监听异常 调用 onWebSocketError
             * @type {Index.methods.onWebSocketError}
             */
            this.websocket.onerror = this.onWebSocketError;
            /**
             * 监听服务器发送的消息 调用 onWebSocketMessage
             * @type {Index.methods.onWebSocketMessage}
             */
            this.websocket.onmessage = this.onWebSocketMessage;

            // /**
            //  * 监听窗口关闭
            //  * @type {Index.methods.onWindowUnLoad}
            //  */
            // window.onbeforeunload = this.onWindowUnLoad
        },
        /**
         * 发送心跳
         */
        sendHeartBeat() {
            this.webSocketFun.sendTimeOut = setTimeout(()=>{
                this.sendHeartBeat()
                this.onWebSocketSend(1)
            },this.webSocketFun.timeOut)
        },
        /**
         * 开启心跳检测
         */
        startHeartBeat(){
            this.webSocketFun.timeoutService = setTimeout(()=>{
                this.startHeartBeat()
                this.webSocketFun.heartbeatCounter+=1
                if(this.webSocketFun.heartbeatCounter*1000>this.webSocketFun.timeOut){
                    this.linkStatus = true
                    this.webSocketFun.heartbeatCounter = 0
                    clearTimeout(this.webSocketFun.timeoutService);
                }
            },1000)
        },
        /**
         * 重置心跳
         */
        resetHeartBeat() {
            this.webSocketFun.heartbeatCounter = 0
            clearTimeout(this.webSocketFun.timeoutService);
            this.startHeartBeat()
        },
        /**
         * 重新连接
         */
        onReconnect(){
            this.linkLoading = true
            this.onSocketInit()
        },
        /**
         * websocket 打开监听
         * @type {onWebSocketOpen}
         */
        onWebSocketOpen() {
            this.startHeartBeat()
            this.sendHeartBeat()
            this.linkStatus = false
            this.linkLoading = false
            console.log('监听打开')
        },
        /**
         * websocket 关闭监听
         * @type {onWebSocketClose}
         */
        onWebSocketClose() {
            this.webSocketFun.heartbeatCounter = 0
            clearTimeout(this.webSocketFun.sendTimeOut);
            clearTimeout(this.webSocketFun.timeoutService);
            console.log('监听关闭')
        },
        /**
         * websocket 监听异常
         * @type {onWebSocketError}
         */
        onWebSocketError() {
            this.linkStatus = true
            console.log('监听关闭');
            this.onReconnect()
        },
        /**
         * websocket 监听服务器发送的消息
         * @type {onWebSocketMessage}
         */
        onWebSocketMessage(news) {
            let data = JSON.parse(news.data)
            this.resetHeartBeat()
            switch(data.action) {
                case RECEIVE_INSTRUCTIONS.CALL_NOTIFICATION: //邀请通知
                    this.$refs.ringtones.play()
                    this.calledInfo = data
                    this.calledInfo.userProfilePictureUrl = data.headPortraitUrl
                    this.visibleCalled = true
                    this.status = 2
                    break;
                case RECEIVE_INSTRUCTIONS.INITIATIVE_CALLING: //呼叫过程中刷新
                    this.visibleCalled = this.stataCalled
                    break;
                case RECEIVE_INSTRUCTIONS.RESTORE_SESSION:  //刷新
                    this.webRTC.channel = data.sessionId;
                    this.webRTC.token = data.roomToken;
                    this.videoClientStatus = true
                    this.operationVisible = true
                    this.agoraId = data.agoraId
                    this.pushStreamAgoraId = data.pushStreamAgoraId
                    this.onWebRTCStartUp().then()
                    this.visibleCalled = false
                    this.onRoomAliveInfoService().then()
                    var params = {
                        action:SEND_COMMAND.MICROPHONE_STATE_SET,
                        enable:this.volumeStatus
                    }
                    this.onWebSocketSend(params)
                    break;
                case RECEIVE_INSTRUCTIONS.USERS_STATUS_CHANGE_NOTICE:  //用户状态变更指令
                    if(!this.videoClientStatus){
                        this.memberListVisible = false
                    }
                    this.userStatusInfo = data
                    break;
                case RECEIVE_INSTRUCTIONS.CALL_RESULT: //邀请结果通知
                    this.visibleCalled = false
                    this.clearInterval()
                    switch (data.result) {
                        case RECEIVE_INSTRUCTIONS.ACCEPT:
                            Message.info('对方已接受！')
                            break;
                        case RECEIVE_INSTRUCTIONS.REFUSE:
                            Message.error('对方已拒绝！')
                            break;
                        case RECEIVE_INSTRUCTIONS.OFFLINE:
                            Message.info('对方已下线！')
                            break;
                        case RECEIVE_INSTRUCTIONS.BUSY:
                            Message.error('对方繁忙中！')
                            break;
                    }
                    this.$refs.ringtones.pause()
                    this.$refs.ringtones.load()
                    break;
                case RECEIVE_INSTRUCTIONS.HANG_UP_NOTIFICATION:  //主动呼叫方挂断
                    this.$refs.ringtones.pause()
                    this.$refs.ringtones.load()
                    this.$refs.hangUp.play()
                    this.visibleCalled = false;
                    break;
                case RECEIVE_INSTRUCTIONS.SESSION_CLOSE:  //会话关闭
                    Message.error('会话已结束！')
                    this.onLeaveCall().then()
                    this.videoClientStatus = false
                    this.menuVisible = true
                    break;
                case RECEIVE_INSTRUCTIONS.MULTI_DEVICE_LOGIN_NOTICE:  //多终端登录提醒
                    Message.warning({
                        content:"您的账号已于"+data.loginTime+"在其他"+data.terminal+"设备上登录！",
                        duration: 5,
                    })
                    // Cookies.remove('token');
                    Cookies.remove('token')
                    this.$router.push('/login').then()
                    break;
                case RECEIVE_INSTRUCTIONS.LOGIN_SWITCH_MESSAGE:
                    if(data.type === 'ASK_ABOUT_SWITCHING'){
                        this.requestStatus = true

                        this.startRequestHeartBeat()
                    }
                    break;
                case RECEIVE_INSTRUCTIONS.SESSION_OPEN:  //会话开启
                    this.webRTC.channel = data.sessionId;
                    this.webRTC.token = data.roomToken;
                    this.videoClientStatus = true
                    this.operationVisible = true
                    this.agoraId = data.agoraId
                    this.pushStreamAgoraId = data.pushStreamAgoraId
                    this.memberListVisible = false
                    this.menuVisible = true
                    this.SET_VISIBLE_CALLED(false)
                    this.onWebRTCStartUp().then()
                    this.onRoomAliveInfoService().then()
                    break;
                case RECEIVE_INSTRUCTIONS.CAMERA_CONFIG: //相机配置信息通知
                    this.cameraMaxZoom = data.maxZoom
                    break;
                // case RECEIVE_INSTRUCTIONS.CAMERA_CONTROLLER_INFO: //相机缩放信息通知
                //     this.selectCameraZoom = data.zoom
                //     break;
                case RECEIVE_INSTRUCTIONS.MEMBER_JOIN:  //成员加入
                    data.microphoneEnabled = true
                    this.memberArray.push(data)
                    Message.info(data.name+'已加入了会话！');
                    this.webRTC.client.enableAudioVolumeIndicator();
                    this.onSubscribeAudio()
                    break;
                case RECEIVE_INSTRUCTIONS.MEMBER_LEAVE:  //会话成员离开
                    Message.info(data.name+'已离开了会话！');
                    this.memberArray.some((member,index) => {
                        if(member.uid === data.uid){
                            this.memberArray.splice(index,1)
                        }
                    })
                    break;
                case RECEIVE_INSTRUCTIONS.MESSAGE_NOTIFICATION:  //消息通知

                    switch (data.contentType){
                        case 'text':  //接收到文本消息
                            Chat.info({
                                name:data.name,
                                color:'#5E87EE',
                                type:'text',
                                content:data.content
                            })
                            break;
                        case 'image': //接收到图片消息
                            Chat.info({
                                name:data.name,
                                type:'image',
                                content:data.content
                            })
                            break;
                    }
                    break;
                case RECEIVE_INSTRUCTIONS.SCREEN_SHARING_NOTIFY: //屏幕共享通知
                    if(data.enable === 'open'){
                        // this.onSharePublished(data.agoraPushStreamidId)
                        // this.shareVideoVisible = true
                        this.videoScreenSwitch = false
                        this.shareStatus = true
                        // this.shareStreamVisible = true
                        this.onRoomAliveInfoService().then(()=>{
                            for(let i in this.memberArray){
                                if(this.memberArray[i].uid === data.uid){
                                    this.memberArray[i].share = true
                                }
                            }
                        })
                    }
                    if(data.enable === 'close'){
                        this.shareStatus = false
                        // this.shareStreamVisible = false
                        for(let i in this.memberArray){
                            if(this.memberArray[i].uid === data.uid){
                                this.memberArray[i].share = false
                            }
                        }
                    }
                    this.$forceUpdate()
                    this.$nextTick(()=>{
                        this.onWindow() //停止共享后调整视频区域计算
                    })
                    break;
                case RECEIVE_INSTRUCTIONS.SCREEN_SHARING_RECOVER: //屏幕共享恢复（共享者）
                    AgoraRTC.createScreenVideoTrack({
                        encoderConfig: "720p_1",
                        // 设置视频传输优化策略为清晰优先或流畅优先。
                        // optimizationMode: "detail"
                    }).then(localScreenTrack => {
                        this.webRTC.client.publish([localScreenTrack]).then()
                        this.shareStatus = true
                        this.webRTC.localScreenTrack = localScreenTrack
                        localScreenTrack.on('track-ended',()=>{
                            this.shareStatus = false
                            this.webRTC.client.unpublish([localScreenTrack]).then()
                            let params = {
                                action:SEND_COMMAND.SCREEN_SHARING_OFF,
                            }
                            this.onWebSocketSend(params)
                        })
                    }).catch(()=>{
                        this.webRTC.client.unpublish([this.webRTC.localScreenTrack]).then()
                        let params = {
                            action:SEND_COMMAND.SCREEN_SHARING_OFF,
                        }
                        this.onWebSocketSend(params)
                        this.onWindow() //停止共享后调整视频区域计算
                    });
                    break;
                case RECEIVE_INSTRUCTIONS.APPLY_SCREEN_SHARING_RESULT:
                    if(data.result === 'SUCCESS'){
                        AgoraRTC.createScreenVideoTrack({
                            encoderConfig: "720p_1",
                            // 设置视频传输优化策略为清晰优先或流畅优先。
                            // optimizationMode: "detail"
                        }).then(localScreenTrack => {
                            this.webRTC.client.publish([localScreenTrack]).then()
                            this.shareStatus = true
                            this.webRTC.localScreenTrack = localScreenTrack
                            localScreenTrack.on('track-ended',()=>{
                                this.shareStatus = false
                                this.webRTC.client.unpublish([localScreenTrack]).then()
                                let params = {
                                    action:SEND_COMMAND.SCREEN_SHARING_OFF,
                                }
                                this.onWebSocketSend(params)
                            })
                        }).catch(()=>{
                            this.webRTC.client.unpublish([this.webRTC.localScreenTrack]).then()
                            let params = {
                                action:SEND_COMMAND.SCREEN_SHARING_OFF,
                            }
                            this.onWebSocketSend(params)
                            this.onWindow() //停止共享后调整视频区域计算
                        });
                    }
                    if(data.result === 'FAIL'){
                        Message.error('屏幕共享失败！')
                    }
                    break;
                case RECEIVE_INSTRUCTIONS.ACTION_NOTIFICATION:  //动作指令
                    switch (data.type) {
                        case "TAG":   //标记
                            Sign.info({
                                offsetLeft: data.content.pageX * document.getElementById('videoHandle').offsetWidth,
                                offsetTop: data.content.pageY * document.getElementById('videoHandle').offsetHeight,
                                color: data.content.signColor,
                                signName:data.content.signName
                            })
                            break;
                        case "OPEN_DRAWING":   //开启画板
                            this.screenLoading = true
                            this.operationVisible = false
                            this.screenshotVisible = true
                            this.screenshotUid = data.uid
                            this.memberRemoteVideoTrack.play(this.memberPlayerContainer);
                            var status = {
                                videoClientStatus:data.content.visible,
                                url:data.content.url
                            }
                            this.screenshotObj = status
                            $event.$emit(EVENT_NAME.SUBSCRIBER_DRAW,status);
                            break;
                        case "CLOSE_DRAWING": //解除截图
                            this.operationVisible = true
                            this.screenshotVisible = false
                            this.screenshotOut = false
                            this.remoteVideoTrack.play(this.playerContainer);
                            this.videoScreenSwitch = false
                            $event.$emit(EVENT_NAME.EMPTY_LINE_DATA); //清除数组
                            for(let i in this.memberArray){
                                if(this.memberArray[i].pushStream){
                                    this.memberArray.unshift(this.memberArray.splice(i,1)[0])
                                }
                            }
                            this.$nextTick(()=>{
                                document.getElementById('memberVideo').append(this.memberPlayerContainer)
                            })
                            break;
                        case "DRAWING_DOWN": //按下动作
                            $event.$emit(EVENT_NAME.MOUSE_DOWN, data.content);
                            break;
                        case "DRAWING_UP": //抬起动作
                            $event.$emit(EVENT_NAME.DRAWING_UP, data.content);
                            if(this.videoScreenSwitch){
                                this.onVideoCanvas()
                            }
                            break;
                        case "DRAWING": //涂鸦(绘画)
                            $event.$emit(EVENT_NAME.PUBLISHER_DRAWING,data.content)
                            break;
                        case "DRAWING_RECALL": //撤销
                            $event.$emit(EVENT_NAME.UNDO_DRAW, data.content.uid);
                            if(this.videoScreenSwitch){
                                this.onVideoCanvas()
                            }
                            break;
                        case "DRAWING_CLEAR": //清除涂鸦
                            $event.$emit(EVENT_NAME.REMOVE_GRAFFITI,data.content.uid);
                            if(this.videoScreenSwitch){
                                this.onVideoCanvas()
                            }
                            break;
                    }
                    break;
                case RECEIVE_INSTRUCTIONS.MICROPHONE_STATE_CHANGE_NOTIFY:  //话筒状态改变通知
                    for(let i in this.memberArray){
                        if(this.memberArray[i].uid === data.uid){
                            this.memberArray[i].microphoneEnabled = data.enable
                        }
                    }
                    break
            }
        },
        /**
         * websocket 发送消息给服务器
         * @param send
         */
        onWebSocketSend(send) {
            try {
                this.websocket.send(JSON.stringify(send));
            } catch (error) {
                Message.error("发送消息 (" + error.code + ") 失败！");
            }
        },
        /**
         * 发送资料文件
         * @param link
         */
        onSendImageLink(link){
            Chat.info({
                name:'我',
                type:'image',
                content: link,
            })
            let params = {
                action:SEND_COMMAND.SEND_IMAGE,
                content:{
                    id:this.state.uuid,
                    url:link,
                }
            }
            this.onWebSocketSend(params)
        },
        /**
         * 查询列表
         */
        onDeviceList(){
            this.onDeviceListAll().then()
        },
        /**
         * 查询设备信息
         * @param id
         */
        onDeviceQuery(id){
            this.listVisible = false
            this.onDeviceInfo(id).then()
            this.onFailureList(id).then()
        },
        /**
         * 拉取分类列表
         */
        async onDeviceListAll() {
            try {
                this.deviceListArray = {}
                let params = {
                    deviceType:'',
                    manufacturer:'',
                    search:this.deviceListParams.search,
                    displayNumber:this.deviceListParams.displayNumber,
                    currentPage:this.deviceListParams.currentPage
                }
                const { code,data } = await getDeviceListService(params)
                if(code === 'SUCCESS'){
                    this.deviceListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 根据设备id查询当前条数据
         */
        async onDeviceInfo(id) {
            try {
                let params = {
                    id:id
                }
                const { code,data } = await getDeviceInfoService(params)
                if(code === 'SUCCESS'){
                    this.deviceInfoObj = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 查询故障分析
         */
        async onFailureList(id) {
            try {
                let params = {
                    id:id,
                }
                const { code,data } = await getFailureListService(params)
                if(code === 'SUCCESS'){
                    if( data.faultAnalysisList.length !== 0){
                        this.faultsList = data.faultAnalysisList
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取存活的会话信息
         */
        async onRoomAliveInfoService() {
            try {
                let params = {
                    sessionId:this.webRTC.channel
                }
                const { code,data} = await getRoomAliveInfoService(params)
                if(code === 'SUCCESS'){
                    this.memberArray = data //pushStream
                    for(let i in this.memberArray){
                        this.memberArray[i].visible = false
                        this.memberArray[i].number = 0
                        if(this.memberArray[i].uid === this.state.uuid){
                            this.memberArray[i].microphoneEnabled = true
                        }
                        if(this.memberArray[i].pushStream){
                            this.memberArray.unshift(this.memberArray.splice(i,1)[0])
                        }
                    }
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
    },
    destroyed () {
        /**
         *  销毁监听
         */
        this.websocket.close()
        this.webRTC.localScreenTrack = null
        $event.$off(EVENT_NAME.COOPERATION_USER)
        window.removeEventListener('keydown',this.onKeyDown,false);
        window.onresize = null
        this.shareStatus = false
        $event.$off([EVENT_NAME.COOPERATION_USER,EVENT_NAME.HEADER_MENU_TAB,EVENT_NAME.SESSION_TIME])
    }
}
export default Index
