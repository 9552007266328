<template>
    <div class="yb-live-layout">
        <!--s: header-->
        <Header/>
        <!--e: header-->

        <!--s: content-->
        <div class="layout-content-box">

            <!--s: menu-->
            <div class="layout-menu" v-show="(menuVisible && !videoClientStatus) || (videoClientStatus && headerMenuId !== 1)">

                <!--s: menu top-->
                <div class="menu-top" v-if="headerMenuId===2">
                    <div class="menu-tab">目录</div>
                </div>
                <!--e: menu top-->

                <!--s: menu list-->
                <MenuList v-model="userStatusInfo" v-show="headerMenuId === 1" menu-type="memberList"/>
                <!--e: menu list-->

                <!--s: menu knowledge-->
                <div class="menu-knowledge" v-show="headerMenuId === 2">
                    <MenuKnowledge/>
                </div>
                <!--e: menu knowledge-->

                <!--s: menu workflow-->
                <div class="menu-workflow" v-show="headerMenuId === 3">
                    <MenuWorkflow/>
                </div>
                <!--s: menu workflow-->

            </div>
            <!--d: menu-->

            <!--s: member list-->
            <div class="layout-member-list menu-body" v-show="videoClientStatus && headerMenuId === 1 && menuVisible">
                <div class="member-number">成员信息({{ memberArray.length }})</div>

                 <div class="member-list">
                     <ul>
                         <li :class="member.visible && member.microphoneEnabled?'active':''" :key="key" v-for="(member,key) in memberArray">
                             <Avatar v-if="(!screenshotVisible && member.pushStream && !videoScreenSwitch) || !member.pushStream || (screenshotUid !== member.uid && videoScreenSwitch)" shape="square" :src=" member.profilePictureUrl || defaultAvatar"/>
                             <div class="member-video" v-if="member.pushStream">
                                 <div class="box" id="memberVideo" v-show="screenshotVisible && !screenLoading">
                                     <span class="video-icon" @click="onVideoScreenSwitch"  v-if="screenshotVisible || shareStreamVisible"><Icon type="ios-swap" size="20"></Icon></span>
                                 </div>
                                 <Spin v-show="screenLoading" fix class="screen-loading">请稍候...</Spin>
                             </div>
                             <div class="member-image" v-if="screenshotUid === member.uid && videoScreenSwitch">
                                 <span class="video-icon" @click="onScreenVideoSwitch" ><Icon type="ios-swap" size="20"></Icon></span>
                                 <img :src="screenImage"/>
                             </div>
                             <div class="member-list-name">
                                 <span class="member-request" v-if="member.pushStream"><Icon custom="icon-default-avatar-white"/></span>
                                 <div class="name" :class="onShareStyle(member)">
                                     <div class="voice-box">
                                         <Icon custom="voice"/>
                                         <span class="mic-off" v-if="!member.microphoneEnabled"></span>
                                         <div class="voice-play" v-if="member.microphoneEnabled">
                                             <span :style="'height:'+(member.visible && member.microphoneEnabled?member.number+20:member.number)+'%'"></span>
                                         </div>
                                     </div>
                                     {{ member.name }}
                                     <span class="share-text" v-if="member.share">正在共享屏幕</span>
                               </div>
                             </div>
                         </li>
                     </ul>
                 </div>

                 <div class="member-invitation">
                     <a href="javascript:" @click="memberListVisible = true;"><Icon custom="icon-add-member"/>邀请成员</a>
                 </div>

                 <!--s: 人员列表-->
                 <div class="member-menu-list yb-menu-fadeinL" v-if="memberListVisible">
                     <MenuList v-model="userStatusInfo" menu-type="sessionMemberList" @on-session-visible="memberListVisible = false"/>
                 </div>
                 <!--e: 人员列表-->

            </div>
            <!--e: member list-->

            <!--s: knowledge content-->
            <div class="layout-knowledge-content" v-show="headerMenuId === 2 || headerMenuId === 3">
                <router-view />
            </div>
            <!--e: knowledge content-->
            <!--s: content-->
            <div class="layout-content" v-show="headerMenuId === 1">

                <!--s: Menu Visible-->
                <div class="menu-visible" @click="onMenuBack" v-if="!memberListVisible" :style="menuVisible && windowWidth<=750 && !videoClientStatus?'left:270px;':'0px'">
                    <Icon :type="menuVisible?'ios-arrow-back':'ios-arrow-forward'"/>
                </div>
                <!--e: Menu Visible-->
                <div class="content"></div>

                <!--s: 视频区域-->
                <div class="layout-video-box" v-if="videoClientStatus">

                    <!--s: 视频开启loading-->
                    <div v-if="agoraVideoLoading" class="agora_video_loading">
                        <div class="invitation-animation-box">
                            <div class="invitation-animation">
                                <i class="invitation-i snakeEating delay1"></i>
                                <i class="invitation-i snakeEating delay2"></i>
                                <i class="invitation-i snakeEating delay3"></i>
                                <i class="invitation-i snakeEating delay4"></i>
                                <i class="invitation-i snakeEating delay5"></i>
                                <i class="invitation-i snakeEating delay6"></i>
                            </div>
                        </div>
                    </div>
                    <!--e: 视频开启loading-->

                    <div class="video-box" @click="onPutAway">
                        <div class="video-time">远程协助时长&nbsp;&nbsp; {{ sessionTime }}</div>
                        <div id="agoraRTC" class="agora_video_box">
                            <div id="videoHandle" class="video-handle-box">
                                <div class="video_box_handle">
                                    <div id="videoBox" class="video-box" @click="videoScreenSwitch = true;shareVideoVisible = false" v-show="!screenshotVisible"></div>
                                    <Spin v-show="screenLoading" fix class="screen-loading" id="screen-area">请稍候...</Spin>
                                    <div class="video-drop-box" id="videoDrop" @click="onSignDrop"></div>
                                    <div class="video-screenshot-top" v-if="operationTopVisible">
                                        <a href="javascript:" class="boundary" v-if="cameraMaxZoom!==1">
                                            <Icon custom="camera-icon" @click="cameraZoomVisible = !cameraZoomVisible" @click.stop/>
                                            <div class="handle-tagging-camera" v-if="cameraZoomVisible">
                                                <span class="title">镜头缩放</span>
                                                <a href="javascript:" :class="selectCameraZoom === 0?'active':''" @click="onSelectCameraZoom(0)">1</a>
                                                <a href="javascript:" :key="key" :class="selectCameraZoom === key?'active':''" v-for="key in cameraMaxZoom" @click="onSelectCameraZoom(key)">{{ key+1 }}</a>
                                            </div>
                                        </a>
                                        <a href="javascript:" @click="onVolume" class="boundary"><Icon :type="volumeStatus?'ios-mic':'ios-mic-off'" /></a>
                                        <a href="javascript:" @click="onVoice" class="boundary"><Icon :type="voiceStatus?'ios-volume-up':'ios-volume-off'" /></a>
                                        <a href="javascript:" @click="onScreen"><Icon :custom="!fullscreen?'full-screen-icon':'screen-zoom-out-icon'" /></a>
                                    </div>
                                    <Screenshot v-model="screenshotVisible" :video-screen-switch="videoScreenSwitch" @on-click-switch="videoScreenSwitch = false" :color="graffitiColor" :name="state.name" :line-width="lineStroke" :menu-visible="menuVisible" @on-draw-line="onGraffiti" @on-mouse-down="onMouseDown" @on-draw-up="onMouseUp" @on-screenshot="onScreenshot"></Screenshot>
<!--                                    <div id="share" class="video-share-box" :class="shareVideoVisible?'video-share-absolute':''" @click="shareVideoVisible = true" v-if="shareStreamVisible"></div>-->
                                    <div class="video-chat">
                                        <div class="chat-info" id="videoChat"></div>
                                        <div class="chat-input" v-if="textVisible">
                                            <input type="text" v-model="newsInput" class="input" placeholder="说点什么"/>
                                            <div class="chat-send-box">
                                                <div class="chat-box">
                                                    <input type="file" name="file" accept="image/*" multiple="multiple" ref="inputer" class="box-file" @change="onSignUpLoad($event)">
                                                    <Icon custom="chat-img"/>
                                                </div>
                                                <div class="chat-send" @click="textVisible = false;textChatVisible = true"></div>
                                            </div>
                                        </div>
                                        <a href="javascript:" class="video-im" v-if="textChatVisible" @click="textVisible = true;textChatVisible=false">
                                           <img src="../../assets/Icon/chat-icon.png"/>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!--s: 底部操作区域-->
                        <div class="video-handle" v-if="operationVisible">
                            <a href="javascript:" v-if="!shareStatus" class="handle-box" :class="active === 1? 'active':''">
                                <div class="handle-tag" :class="signColorVisible?'tagging':'tagging-down'" @click="onHandleSelect(1)" @click.stop>
                                    <Icon type="ios-disc-outline" :style="'color:'+signColor"/>
                                    <font>标注</font>
                                </div>
                                <div class="handle-tagging-box" v-if="signColorVisible">
                                    <h2 class="tagging-title">动态标注颜色</h2>
                                    <div class="sign-color">
                                        <a href="javascript:" :class="signColor == colorPicker.color? 'select': '' " :key="key" v-for="(colorPicker,key) in colorPickerObj" @click="onSignColor(colorPicker.color)"><span class="dot" :style="'background:'+colorPicker.color"></span></a>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:" class="handle-box" v-if="!shareStatus" :class="active === 2? 'active':''" @click="onHandleSelect(2)">
                                <Icon custom="icon-screenshot-white" />
                                <font>截图</font>
                            </a>
                            <a href="javascript:" class="handle-box" v-if="!shareStatus" :class="onShareStatus(active)" @click="onHandleSelect(5)">
                                <Icon custom="icon-share-white" />
                                <font>共享屏幕</font>
                            </a>
<!--                            <a href="javascript:" class="handle-box" :class="active === 3? 'active':''" @click="onHandleSelect(3)">-->
<!--                                <Icon type="ios-videocam-outline" />-->
<!--                                <font>录屏</font>-->
<!--                            </a>-->
<!--                            <a href="javascript:" class="handle-box" :class="active === 4? 'active':''" @click="onHandleSelect(4)">-->
<!--                                <Icon type="ios-chatboxes-outline" />-->
<!--                                <font>聊天</font>-->
<!--                            </a>-->
<!--                            <a href="javascript:" class="handle-box" :class="active === 6? 'active':''" @click="onHandleSelect(6)">-->
<!--                                <Icon type="ios-bookmarks-outline" />-->
<!--                                <font>知识库</font>-->
<!--                            </a>-->
                            <Button type="error" @click="onHandUp" class="btn-refuse"><Icon type="ios-power" />结束</Button>
                        </div>
                        <!--e: 底部操作区域-->

                        <!--s: 底部截图区域-->
                        <div class="video-handle" v-if="screenshotVisible || (!screenshotVisible && videoScreenSwitch)">
                            <span v-if="screenshotOut" class="video-screenshot-remove" @click="onSignOut"><Icon type="ios-undo" size="20" style="margin-right: 5px"/>返回</span>
                            <a href="javascript:" class="handle-box" :class="screenshotActive === 1? 'active':''">
                                <div class="handle-tag" :class="graffitiVisible?'tagging':'tagging-down'" @click="onScreenshotSelect(1)" @click.stop>
                                    <i class="handle-record" :style="'background:'+graffitiColor"></i>
                                    <font>画笔颜色</font>
                                </div>

                                <div class="handle-tagging-box" v-if="graffitiVisible">
                                    <h2 class="tagging-title">画笔颜色</h2>
                                    <div class="sign-color">
                                        <a href="javascript:" :class="graffitiColor == colorPicker.color? 'select': '' " :key="key" v-for="(colorPicker,key) in colorPickerObj" @click="onGraffitiColor(colorPicker.color)"><span class="dot" :style="'background:'+colorPicker.color"></span></a>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:" class="handle-box" :class="screenshotActive === 2? 'active':''" >
                                <div class="handle-tag" :class="lineVisible?'tagging':'tagging-down'" @click="onScreenshotSelect(2)" @click.stop>
                                    <span class="handle-record-line">
                                        <i :class="'line-'+lineStroke"></i>
                                    </span>
                                    <font>画笔粗细</font>
                                </div>

                                <div class="handle-line-box" v-if="lineVisible">
                                    <h2 class="tagging-title">画笔粗细</h2>
                                    <div class="sign-line">
                                        <a href="javascript:" :key="key" v-for="(line,key) in lineStrokeObj" :class="lineStroke == line.stroke? 'select' : ''" @click="online(line.stroke)"><span :class="'line-'+line.stroke" ></span></a>
                                    </div>
                                </div>
                            </a>
                            <a href="javascript:" class="handle-box handleActive" @click="onScreenshotSelect(3)">
                                <Icon type="ios-undo" />
                                <font>撤销</font>
                            </a>
                            <a href="javascript:" class="handle-box handleActive" @click="onScreenshotSelect(4)">
                                <Icon type="ios-trash-outline" />
                                <font>清除</font>
                            </a>
                            <a href="javascript:" class="handle-box handleActive" @click="onScreenshotSelect(5)">
                                <Icon type="ios-paper-plane-outline" />
                                <font>发送</font>
                            </a>
                        </div>
                        <!--e: 底部截图区域-->

                        <!--s: 知识库-->
                        <div class="video-device" v-if="deviceVisible">
                            <div class="device-title">
                                <span class="name">知识库</span>
                                <a href="javascript:" @click="deviceVisible = false"><Icon type="ios-close" :size="30"/></a>
                            </div>
                            <div class="list" v-show="listVisible">
                                <div class="device-search">
                                    <Input search enter-button="搜索" v-model="deviceListParams.search" @on-search="onDeviceList"  placeholder="请输入您要查询的设备" />
                                </div>
                                <div class="device-list-tab">
                                    <ul>
                                        <li :key="key" v-for="(deviceList,key) in deviceListArray.content" @click="onDeviceQuery(deviceList.id)">
                                            <img :src="deviceList.equipmentPictures" class="image">
                                            <div class="box">
                                                <h1 class="name">{{ deviceList.equipmentName }}</h1>
                                                <span class="text">产品编号：{{ deviceList.serialNumber}}</span>
                                                <span class="text">产品分类：</span>
                                                <span class="text">产品型号：{{ deviceList.modelName }}</span>
                                                <span class="text">生产厂家：{{ deviceList.manufacturerName }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="video-device-content" v-show="!listVisible">
                                <div class="content-title">
                                    <a href="javascript:" style="float:left" @click="listVisible = true"><Icon type="md-arrow-back" :size="20"/></a>
                                </div>
                                <div class="content-info">
                                    <img :src="deviceInfoObj.equipmentPictures" class="image">
                                    <div class="text">
                                        <h1 class="name">{{ deviceInfoObj.name}}</h1>
                                        <ul>
                                            <li>设备型号：无</li>
                                            <li>分类：无</li>
                                            <li>生产厂家：无</li>
                                            <li>创建人：{{ deviceInfoObj.updateUserName}}</li>
                                            <li>创建时间：{{ deviceInfoObj.updateTime}}</li>
                                        </ul>
                                    </div>
                                </div>
                              <div class="layout-device-tab">
                                <Tabs :animated="false">
                                  <TabPane label="资料文件">
                                    <div class="tab-box">
                                      <div class="title">
                                        <span class="name">使用说明</span>
                                      </div>
                                      <div class="table">
                                        <Table ref="selection" :columns="deviceDataVideoColumns" :data="deviceInfoObj.instructionUseFiles" >
                                          <template slot-scope="{ index }" slot="序号">
                                            {{ index+1 }}
                                          </template>
                                          <template slot-scope="{ row }" slot="文件名称">
                                            <a :href="row.file" class="target" target="_blank">{{ row.fileName }}</a>
                                          </template>
                                          <template slot-scope="{ row }" slot="文件大小">
                                            {{ renderSize(row.fileSize) }}
                                          </template>
                                          <template slot-scope="{ row }" slot="操作">
                                            <a :href="row.file" target="_blank">查看文件</a>
                                            <a href="javascript:" @click="onSendImageLink(row.file)">发送</a>
                                          </template>
                                        </Table>
                                      </div>
                                    </div>
                                    <div class="tab-box">
                                      <div class="title">
                                        <span class="name">机械图册</span>
                                      </div>
                                      <div class="table">
                                        <Table ref="selection" :columns="deviceDataVideoColumns" :data="deviceInfoObj.mechanicalAtlasFiles" >
                                          <template slot-scope="{ index }" slot="序号">
                                            {{ index+1 }}
                                          </template>
                                          <template slot-scope="{ row }" slot="文件名称">
                                            <a :href="row.file" class="target" target="_blank">{{ row.fileName }}</a>
                                          </template>
                                          <template slot-scope="{ row }" slot="文件大小">
                                            {{ renderSize(row.fileSize) }}
                                          </template>
                                          <template slot-scope="{ row }" slot="操作">
                                            <a :href="row.file" target="_blank">查看文件</a>
                                            <a href="javascript:" @click="onSendImageLink(row.file)">发送</a>
                                          </template>
                                        </Table>
                                      </div>
                                    </div>
                                    <div class="tab-box">
                                      <div class="title">
                                        <span class="name">电器图册</span>
                                      </div>
                                      <div class="table">
                                        <Table ref="selection" :columns="deviceDataVideoColumns" :data="deviceInfoObj.electricalAtlasFiles" >
                                          <template slot-scope="{ index }" slot="序号">
                                            {{ index+1 }}
                                          </template>
                                          <template slot-scope="{ row }" slot="文件名称">
                                            <a :href="row.file" class="target" target="_blank">{{ row.fileName }}</a>
                                          </template>
                                          <template slot-scope="{ row }" slot="文件大小">
                                            {{ renderSize(row.fileSize) }}
                                          </template>
                                          <template slot-scope="{ row }" slot="操作">
                                            <a :href="row.file" target="_blank">查看文件</a>
                                            <a href="javascript:" @click="onSendImageLink(row.file)">发送</a>
                                          </template>
                                        </Table>
                                      </div>
                                    </div>
                                    <div class="tab-box">
                                      <div class="title">
                                        <span class="name">其他文件</span>
                                      </div>
                                      <div class="table">
                                        <Table ref="selection" :columns="deviceDataVideoColumns" :data="deviceInfoObj.otherAtlasFiles" >
                                          <template slot-scope="{ index }" slot="序号">
                                            {{ index+1 }}
                                          </template>
                                          <template slot-scope="{ row }" slot="文件名称">
                                            <a :href="row.file" class="target" target="_blank">{{ row.fileName }}</a>
                                          </template>
                                          <template slot-scope="{ row }" slot="文件大小">
                                            {{ renderSize(row.fileSize) }}
                                          </template>
                                          <template slot-scope="{ row }" slot="操作">
                                            <a :href="row.file" target="_blank">查看文件</a>
                                            <a href="javascript:" @click="onSendImageLink(row.file)">发送</a>
                                          </template>
                                        </Table>
                                      </div>
                                    </div>
                                  </TabPane>
                                  <TabPane label="故障分析">
                                    <div class="tab-ul">
                                      <ul>
                                        <li :key="key" v-for="(faults,key) in faultsList" :style="key!==0?'border-top:1px dashed #dcdcdc':''">
                                          <h1 class="title">
                                            <span class="name">故障{{ key+1 }}</span>
                                            <a href="javascript:"><Icon type="ios-arrow-down" :size="20"/></a>
                                          </h1>
                                          <div class="box" >
                                            <div class="text">
                                              <span class="name">故障描述</span>
                                              <span style="width: calc(100% - 80px)">{{ faultsList[key].describe }}</span>
                                            </div>
                                            <div class="reason">
                                              <div class="reason-li">
                                                <ul>
                                                  <li :key="index" v-for="(reasons,index) in faultsList[key].reasons">
                                                    <span class="name">可能的原因{{ index+1 }}</span>
                                                    <div class="reason-box">
                                                      <div class="reason-text">
                                                        <h2 class="title">原因</h2>
                                                        <span style="width:100%">{{ faultsList[key].reasons[index].reason }}</span>
                                                      </div>
                                                      <div class="reason-method">
                                                        <h2 class="title">处理方法</h2>
                                                        <span style="width:100%">{{ faultsList[key].reasons[index].solution }}</span>
                                                      </div>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </TabPane>
                                </Tabs>
                              </div>
                            </div>
                        </div>
                        <!--e: 知识库-->

                    </div>

                </div>
                <!--e: 视频区域-->

                <!--s: footer-->
                <div class="footer">
                    <div class="footer-box">{{ systemPublishLogo === systemPublishMenu.NORMAL?'灵云':'' }}V1.0&#8194;&#8194;&#8194;&#8194;&#8194;&#8194;&#8194;Copyright&#8194;©2019-2023&#8194;</div>
                </div>
                <!--e: footer-->

            </div>
            <!--e: content-->

        </div>
        <!--e: content-->
        <audio ref="ringtones" loop src="../../assets/Media/ringtones.mp3" style="display: none"></audio>
        <audio ref="hangUp" src="../../assets/Media/hang-up.mp3" style="display: none"></audio>
        <!--s: 呼叫vs来电-->
        <Called
            v-model="visibleCalled"
            @on-hang-up="onCalledHangUp"
            @on-answer="onCalledAnswer"
            @on-cancel="onCancel"
            :called-info="calledInfo"
            :status="status"
        />
        <Modal
            v-model="linkStatus"
            :closable="false"
            :mask-closable="false"
            footer-hide
            class-name="link-center-modal">
            <div class="link-center">
                <p class="link-prompt">您已断线，请重新连接!</p>
                <div class="link-footer">
                    <Button type="primary" :loading="linkLoading" @click="onReconnect">重新连接</Button>
                </div>
            </div>
        </Modal>
        <Modal
            v-model="requestStatus"
            :width="416"
            :closable="false"
            :mask-closable="false"
            footer-hide>
            <div class="result-modal-confirm">
                <div class="result-modal-confirm-head">
                    <div class="head-icon"><Icon type="ios-help" size="28"></Icon></div>
                    <div class="head-modal-confirm-head-title">提示</div>
                </div>
                <div class="result-modal-confirm-body">
                    <p>你的账户请求在另一台设备上登录，确定通过吗？</p>
                    <p>确定通过，你将在此台设备上退出登录。</p>
                </div>
                <div class="result-modal-confirm-footer">
                    <Button type="text" @click="onRequestCancel">拒绝</Button>
                    <Button type="primary" @click="onRequestOk">同意（{{ requestFun.heartbeatCounter }}）</Button>
                </div>
            </div>
        </Modal>
        <!--s: -->
    </div>
</template>
<script>
import Index from './index'
export default Index
</script>
<style lang="less">
@import "index";
</style>
